<template>
  <div class="container" :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    theme: {
      type: String,
      //dark && light=>预设白底
      default: "light",
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    className() {
      return this.padding ? this.theme + " padding": this.theme;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
}

.padding {
  padding: 0rem 1.2rem 3.5rem 1.2rem;
}

.light {
  background-color: var(--white);
}

.dark {
  background-color: var(--bg-light-gray);
}
</style>