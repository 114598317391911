<template>
  <div class="NoData">
    <div class="box__img">
      <img src="../assets/images/public/ic_comon_error.webp" alt="" />
    </div>
    <div class="text">暂无数据</div>
  </div>
</template>
<script>
export default {
  name: "NoData",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.NoData{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    .box__img{
        width: 5rem;
        img{
            width: 100%;
        }
    }
    .text{
        width: 100%;
        text-align: center;
        color: #9A9A9A;
    }
}
</style>
