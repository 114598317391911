<template>
  <Container theme="dark" :padding="false" ref="list" @scroll.native="loadMore">
    <template v-if="checkResponseStatus">
      <LogContentItem
        v-for="(item, index) in data"
        :key="index"
        :title="item.loginArea"
        :os="item.os"
        :loginIp="item.loginIp"
        :content="item.sendMessage"
        :createdAt="item.createdAt"
        :id="item.id"
        :class="{ mb_6rem: data.length > 5 && index === data.length - 1 }"
      ></LogContentItem>
    </template>
    <NoData v-else-if="!checkResponseStatus && !isLoading"></NoData>
  </Container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import NoData from "@/components/NoData";
import Container from "../components/Container.vue";
import LogContentItem from "../components/LogContentItem.vue";
export default {
  components: {
    NoData,
    Container,
    LogContentItem,
  },
  data() {
    return {
      title: "",
      page: 1,
      limit: 20,
      data: [],
      dataResponse: true,
    };
  },
  watch: {
    loginLogsData: {
      handler: function(newVal) {
        this.changeSpinnerStatus(true);
        if (newVal) {
          if (newVal === []) return;
          this.data.push(...newVal);
          setTimeout(() => {
            this.changeSpinnerStatus(false);
          }, 800);
        } else {
          this.changeSpinnerStatus(false);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState("spinner", ["isLoading"]),
    ...mapState("loginLogs", ["loginLogsData"]),
    checkResponseStatus() {
      return this.data && this.data.length > 0 ? true : false;
    },
  },
  methods: {
    ...mapActions("spinner", ["changeSpinnerStatus"]),
    ...mapActions("loginLogs", ["getLoginLogs"]),
    loadMore(e) {
      if (!this.dataResponse) return;
      let { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollHeight === scrollTop + clientHeight) {
        this.page++;
        this.getLoginLogs({
          page: this.page,
          limit: this.limit,
        }).then((res) => {
          if (res.data && res.data.length === 0) {
            this.dataResponse = false;
          }
        });
      }
    },
  },
  created() {
    this.data = [];
    this.getLoginLogs({
      page: this.page,
      limit: this.limit,
    });
  },
};
</script>

<style></style>
